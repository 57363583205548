export enum CaseFolderType {
    all = 1,
    own = 2,
    ownArchived = 3,
    shared = 4,
    demo = 5
}

export enum CaseSortType {
    CreationDate = 0,
    LastMessageDate = 1  
}

export enum OperationType {
    General = 1,
    Knee = 2,
    Spine = 3,
    DeformationCorrection = 4,
    Arthrodesis = 5,
    GuidesForTransped = 6,
    THR = 7
}

export enum SceneType {
    General = 1,
    Knee2D = 2,
    Spine = 3,
    OrthoVET = 4,
    Hip2D = 5,
    Maxillofacial = 6
}

export enum PlannerModuleType
    {
        General = 1,
        Modules2D = 2,
        Spine = 3,
        Maxillofacial = 4
    }

export enum CaseStatus {
    New = 1,
    DicomUploading = 2,
    Segmentation = 3,
    ModelsReady = 4,
    OperationPlanningSelf = 5,
    OperationPlanningCollaboration = 6,
    ImplantsDesigning = 7,
    ImplantsDesignReady = 8,
    ImplantsDesignApproval = 9,
    GuidesDesigning = 10,
    GuidesDesignReady = 11,
    GuidesDesignApproval = 12,
    Manufacturing = 13,
    Shipping = 14,
    Done = 15,
    Canceled = 16,
    Deleted = 17
}

export enum Language {
    ruRU = 1,
    enUS = 2
}

export enum MedicalReferals {
    Human = 1,
    Veterinary = 2
}

export enum Speciality {
    NotSet = 0,
    TraumatologistOrthopedist = 1,
    MaxillofacialSurgeon = 2,
    Neurosurgeon = 3,
    Other = 4
}

export enum UserType {
    User = 0,
    Admin = 1,
    Bioengineer = 2,
    PrinterOperator = 3
}

export enum Gender {
    Male = 1,
    Female = 2
}

export enum FileType {
    UNKNOWN = 0,
    DICOM = 1,
    DICOMImages = 2,
    STLMODEL = 3,
    PLANNERMODEL = 4,
    PICTURE = 5,
    VIDEO = 6,
    PDF = 7,
    DOC = 8
}

export enum ModelMaterialType {
    Bone = 1,
    Titanium = 2,
    Plastic = 3,
    Gypsum = 4,
    PEEK = 5
}

export enum SceneObjectType {
    Object3d = 1,
    Label = 2,
    Drawing = 3,
    Dimension = 4,
    Shape = 5,
    DICOMImages = 6,
    Attach = 7
}

export enum MaterialQuality {
    Bad = 0,
    Good = 1
}