import { Component, Inject, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { MedicalReferals, Language, PlannerRoles, Speciality } from '@appmodels';
import { confirmPasswordValidator, ObjectMapper, passwordPattern } from 'app/core/helpers';
import { MatOption, MatAutocomplete, MatAutocompleteSelectedEvent, MatInput } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../i18n/en';
import { locale as russian } from '../i18n/ru';
import { AfterViewInit } from '@angular/core';
import { ViewChildren } from '@angular/core';

@Component({
    selector: 'user-form-dialog',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UserFormDialogComponent implements OnInit, OnDestroy {
    action: string;

    userForm: FormGroup;
    dialogTitle: string;

    user: PlannerUser;
    rolesList: string[];

    medicalReferalsEnum = MedicalReferals;
    specialityEnum = Speciality;
    languageEnum = Language;

    showPasswordFields: boolean = false;

    // @ViewChildren('#password')
    // public passwordElement : MatInput;
    // @ViewChildren('#passwordConfirm')
    // public passwordConfirmElement : MatInput;

    //private _isFirstPasswordChange: boolean = true;

    // Private
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<UserFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<UserFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, russian);
        this._unsubscribeAll = new Subject();
        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit user';
            this.user = { ..._data.user };
        }
        else {
            this.dialogTitle = 'New Contact';
            //  this.contact = new Contact({});
        }

        this.userForm = this.createUserForm(this.user);

        this.setAvalilableRoles();


    }

    ngOnInit(): void {
        this.userForm.get('password').setValue('');
        this.userForm.get('passwordConfirm').setValue('');
    }

    // ngAfterViewInit(): void {
    //     this.userForm.get('password').setValue('');
    //     this.userForm.get('passwordConfirm').setValue('');
    // }

    onRemoveRole(role: string) {
        const roleIndex = this.user.roles.indexOf(role, 0);
        if (roleIndex > -1) {
            this.user.roles.splice(roleIndex, 1);
        }
    }

    onAddRole(event: MatAutocompleteSelectedEvent) {
        const role = event.option.value;

        if (this.user.roles.indexOf(role) >= 0)
            return;

        this.user.roles.push(role);
    }

    onSave() {
        ObjectMapper.mapFormGroupToObject(this.userForm, this.user);

        let userNewPassword = null;

        if(this.showPasswordFields)
            userNewPassword = this.userForm.get('password').value;

        this.matDialogRef.close(['save', this.user, userNewPassword])
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createUserForm(editedUser: PlannerUser = null): FormGroup {
        const form = this._formBuilder.group({
            email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
            lastName: ['', Validators.required],
            firstName: ['', Validators.required],
            middleName: [''],
            medicalReferalsType: ['', Validators.required],
            speciality: [this.specialityEnum.NotSet, this.validateSpeciality],
            country: ['', Validators.required],
            city: ['', [Validators.required]],
            phoneNumber: ['', Validators.required],
            company: ['', Validators.required],
            language: ['', Validators.required],
            password: [''], //[Validators.required, Validators.pattern(passwordPattern)]
            passwordConfirm: [''] // [Validators.required, confirmPasswordValidator]
        });

        if (editedUser) {
            ObjectMapper.mapObjectToFormGroup(editedUser, form);
        }

        form.get('medicalReferalsType').valueChanges.subscribe(value => {

            if (value == MedicalReferals.Veterinary)
                this.userForm.get('speciality').setValue(Speciality.NotSet);

            this.userForm.get('speciality').updateValueAndValidity();
        });

        form.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((val) => {
                //console.log(val);

                // if(this._isFirstPasswordChange)
                // {
                //     this._isFirstPasswordChange = false;
                    
                //     this.userForm.get('password').setValue('');
                //     this.userForm.get('passwordConfirm').setValue('');
                // }

                // console.log(this.passwordElement.value);         

                form.get('passwordConfirm').updateValueAndValidity();
             
            });

        return form;
    }

    setAvalilableRoles() {
        this.rolesList = [];

        this.rolesList.push(PlannerRoles.Admin, PlannerRoles.CasesEditor, PlannerRoles.FilesEditor, PlannerRoles.ImplantsEditor
            , PlannerRoles.AnalyticsEditor, PlannerRoles.LogsEditor, PlannerRoles.UsersEditor, PlannerRoles.AdvancedUser
            , PlannerRoles.UsersEditor, PlannerRoles.SalesEditor, PlannerRoles.Bioengineer, PlannerRoles.PrinterOperator, PlannerRoles.Security);
    }



    // onPasswordChange(val: string) : void
    // {

    // }

    // onMedicalReferalsTypeChange($event): void{

    //     console.log($event);


    // }

    validateSpeciality: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

        if (!control || !control.parent) {
            return null;
        }

        const medicalReferals = control.parent.get('medicalReferalsType').value as MedicalReferals;

        // console.log(control);

        const speciality = control.value as Speciality;

        let isValid = false;

        // console.log(medicalReferals);

        if (medicalReferals == MedicalReferals.Human) {

            if (speciality || speciality != Speciality.NotSet) {
                isValid = true;
            }
            else {
                isValid = false;
            }

        }
        else {
            isValid = true;
        }

        //console.log(isValid);

        return isValid ? null : { wrongSpeciality: true };
    }

    toggleExtraToFields(): void {
        this.showPasswordFields = !this.showPasswordFields;


        let passwordControl = this.userForm.get('password');
        let passwordConfirmControl = this.userForm.get('passwordConfirm');

        passwordControl.setValue('');
        passwordConfirmControl.setValue('');

        passwordControl.setValidators([]);
        passwordConfirmControl.setValidators([]);

        if (this.showPasswordFields) {
            passwordControl.setValidators([Validators.required, Validators.pattern(passwordPattern)]);
            passwordConfirmControl.setValidators([Validators.required, confirmPasswordValidator]);
        }
        else {
          
        }

        passwordControl.updateValueAndValidity();
        passwordConfirmControl.updateValueAndValidity();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    } 
}
