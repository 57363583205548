<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Add scene object</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>



        <form name="composeForm" [formGroup]="sceneObjectEditForm" class="scene-object-edit-form" fxLayout="column"
            fxFlex>

            <div class="show-hide-extra-fields" (click)="showExtraToFields = !showExtraToFields">
                <span [fxShow]="!showExtraToFields">Show transform properties</span>
                <span [fxShow]="showExtraToFields">Hide transform properties</span>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Title</mat-label>
                <input matInput name="title" formControlName="title" type="text">
            </mat-form-field>

            <div fxLayout="row" flexGrow="1" fxLayoutGap="10px" fxLayoutAlign="stretch">

                <mat-form-field fxLayout="column" appearance="outline">
                    <mat-label>Object type</mat-label>
                    <mat-select name="objectType" formControlName="objectType">
                        <mat-option [value]="sceneObjectTypeEnum.Object3d">
                            Object3d
                        </mat-option>
                        <mat-option [value]="sceneObjectTypeEnum.Label">
                            Label
                        </mat-option>
                        <mat-option [value]="sceneObjectTypeEnum.Drawing">
                            Drawing
                        </mat-option>
                        <mat-option [value]="sceneObjectTypeEnum.Dimension">
                            Dimension
                        </mat-option>
                        <mat-option [value]="sceneObjectTypeEnum.Shape">
                            Shape
                        </mat-option>
                        <mat-option [value]="sceneObjectTypeEnum.DICOMImages">
                            DICOMImages
                        </mat-option>
                        <mat-option [value]="sceneObjectTypeEnum.Attach">
                            Attach
                        </mat-option>
                    </mat-select>
                </mat-form-field>


                <div class="mat-form-toggle" fxLayout="column" flexGrow="1">
                    <mat-slide-toggle name="isVisible" formControlName="isVisible">
                        Is visible
                    </mat-slide-toggle>
                </div>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Attached file</mat-label>
                <mat-select name="objectFile" formControlName="objectFile">
                    <mat-option [value]="file" *ngFor="let file of filterByFileType(selectedCase.details.files)">
                        {{file.fileType}}: {{file.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput matTextareaAutosize="true" matAutosizeMinRows="1" rows="1" matAutosizeMaxRows="3"
                    name="description" formControlName="description"></textarea>
            </mat-form-field>

            <mat-form-field appearance="outline"
                [fxShow]="sceneObjectEditForm.controls['objectType'].value == sceneObjectTypeEnum.Label">
                <mat-label>Text</mat-label>
                <textarea matInput matTextareaAutosize="true" matAutosizeMinRows="3" rows="3" matAutosizeMaxRows="3"
                    name="text" formControlName="text"></textarea>
            </mat-form-field>


            <mat-form-field appearance="outline"
                [fxShow]="sceneObjectEditForm.controls['objectType'].value == sceneObjectTypeEnum.Object3d">
                <mat-label>Color</mat-label>
                <input matInput type="color" name="color" formControlName="color" placeholder="Color">
                <div class="color-set-buttons" fxLayout="row" flexGrow="1" fxLayoutGap="2px" justifyContent="center">
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#FF0000" (click)="setSceneObjectColor('#FF0000')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#da3d34" (click)="setSceneObjectColor('#da3d34')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#eb5a46" (click)="setSceneObjectColor('#eb5a46')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#ff8000" (click)="setSceneObjectColor('#ff8000')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#ff9f1a" (click)="setSceneObjectColor('#ff9f1a')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#f2d600" (click)="setSceneObjectColor('#f2d600')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#ffffb3" (click)="setSceneObjectColor('#ffffb3')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#70b500" (click)="setSceneObjectColor('#70b500')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#b3de69" (click)="setSceneObjectColor('#b3de69')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#51e898" (click)="setSceneObjectColor('#51e898')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#00c2e0" (click)="setSceneObjectColor('#00c2e0')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#0088ff" (click)="setSceneObjectColor('#0088ff')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#0079bf" (click)="setSceneObjectColor('#0079bf')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#c377e0" (click)="setSceneObjectColor('#c377e0')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#ff78cb" (click)="setSceneObjectColor('#ff78cb')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#50C3C3" (click)="setSceneObjectColor('#50C3C3')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#7D7D7D" (click)="setSceneObjectColor('#7D7D7D')">&nbsp;</button>
                    <button fxLayout="column" flexGrow="1" class="color-set" mat-raised-button type="button"
                        setBGColor="#DAD7B8" (click)="setSceneObjectColor('#DAD7B8')">&nbsp;</button>
                </div>
            </mat-form-field>

            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Position</mat-label>
                <input matInput name="position" formControlName="position" type="text">
            </mat-form-field>
            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Rotation</mat-label>
                <input matInput name="rotation" formControlName="rotation" type="text">
            </mat-form-field>
            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Scale</mat-label>
                <input matInput name="scale" formControlName="scale" type="text">
            </mat-form-field>
            <mat-form-field appearance="outline" [fxShow]="showExtraToFields">
                <mat-label>Reflection</mat-label>
                <input matInput name="reflection" formControlName="reflection" type="text">
            </mat-form-field>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>

            <!-- (click)="createCase()"  -->
            <button mat-raised-button color="accent" (click)="editSceneObject()" class="save-button"
                [disabled]="sceneObjectEditForm.invalid || requestInProgress" aria-label="Create">
                {{isEditingObject ? 'Edit' : 'Add'}}
            </button>

            <span class="p-4 red-500-fg">
                {{errorText}}
            </span>

            <!-- <button mat-icon-button matTooltip="Attach a file">
                <mat-icon>attach_file</mat-icon>
            </button> -->
        </div>

        <!-- (click)="" -->
        <button mat-icon-button aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>